<template>
    <div class="unit-detail">
        <!-- {{ unitDetailsDto }} -->
        <b-row>
            <b-col cols="12">
                <b-card>
                    <template #header>
                        <div
                            class="d-flex justify-content-start align-items-center"
                        >
                            <unicon
                                name="users-alt"
                                class="mr-1"
                                style="fill: #6e6b7b"
                            ></unicon>
                            <h4 class="m-0">تفاصيل الوحدة</h4>
                        </div>
                        <div
                            class="d-flex justify-content-start align-items-center"
                        >
                            <span>عدد الاشتراكات:</span>
                            <b-badge pill variant="success">{{
                                unitDetailsDto.subCount
                            }}</b-badge>
                        </div>
                    </template>
                    <validationObserver ref="sellPointForm">
                        <b-row>
                            <b-col lg="4" md="6">
                                <ek-input-text
                                    name="الاسم الوحدة"
                                    label="الاسم الوحدة"
                                    v-model="unitDetailsDto.name"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم مطلوب',
                                        },
                                    ]"
                                ></ek-input-text>
                            </b-col>
                            <b-col lg="4" md="6">
                                <ek-input-select
                                    :options="subjectOptions"
                                    name="الصف/المادة"
                                    label="الصف/المادة"
                                    v-model="unitDetailsDto.subjectName"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم مطلوب',
                                        },
                                    ]"
                                ></ek-input-select>
                            </b-col>
                            <b-col lg="4" md="6">
                                <ek-input-select
                                    name="الفصل"
                                    label="الفصل"
                                    :options="semesterList"
                                    v-model="unitDetailsDto.semesterId"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم مطلوب',
                                        },
                                    ]"
                                ></ek-input-select>
                            </b-col>

                            <!-- <b-col lg="4" md="6">
                    <ek-input-select
                    name="الاسم المدرس"
                    label="الاسم المدرس"
                    :options="teachersList"
                    v-model="unitDetailsDto.teacherName"
                    :rules="[
                        {
                        type: 'required',
                        message: 'اسم مطلوب',
                        },
                    ]"
                    ></ek-input-select>
                </b-col> -->
                            <b-col lg="4" md="6">
                                <ek-input-text
                                    name="السعر"
                                    label="السعر"
                                    v-model="unitDetailsDto.price"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم مطلوب',
                                        },
                                    ]"
                                ></ek-input-text>
                            </b-col>
                            <b-col lg="4" md="6">
                                <ek-date-picker
                                    readonly
                                    disabled
                                    name="تاريخ الاضافة"
                                    label="تاريخ الاضافة"
                                    v-model="unitDetailsDto.dateCreated"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم مطلوب',
                                        },
                                    ]"
                                >
                                </ek-date-picker>
                            </b-col>
                            <b-col lg="4" md="6">
                                <ek-input-text
                                    readonly
                                    name="ترتيب"
                                    label="ترتيب"
                                    v-model="unitDetailsDto.order"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'اسم مطلوب',
                                        },
                                    ]"
                                ></ek-input-text>
                            </b-col>
                            <b-col lg="4" md="6">
                                <ek-input-text
                                    readonly
                                    name="عدد الدروس"
                                    label="عدد الدروس"
                                    v-model="unitDetailsDto.lessonsCount"
                                ></ek-input-text>
                            </b-col>

                            <b-col lg="4" md="6">
                                <b-form-group label="حالة الوحده">
                                    <b-form-radio-group
                                        id="radio-isHidden"
                                        :options="options"
                                        v-model="unitDetailsDto.isFree"
                                        name="حالة الوحده"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validationObserver>
                    <template #footer>
                        <b-row>
                            <b-col lg="6">
                                <b-button
                                    variant="main-color"
                                    class="mr-1"
                                    @click="editDetailsUnit"
                                    >تعديل</b-button
                                >
                                <b-button
                                    variant="outline-primary"
                                    @click="$router.go(-1)"
                                    >تراجع</b-button
                                >
                            </b-col>
                            <b-col
                                lg="6"
                                class="d-flex justify-content-end align-items-center"
                            >
                                <b-button
                                    @click="deleteUnits(id)"
                                    variant="outline-primary"
                                    >حذف</b-button
                                >
                            </b-col>
                        </b-row>
                    </template>
                </b-card>
                <div
                    class="px-2 py-1 d-flex justify-content-between align-items-center"
                >
                    <h2>
                        أبحاث الوحدة
                        <unicon
                            name="users-alt"
                            class="mr-1"
                            style="fill: #6e6b7b"
                        ></unicon>
                    </h2>
                    <!-- <span
            >عدد المستندات:
            <b-badge pill variant="success" v-if="unitDetailsDto && unitDetailsDto.chapters">{{
              unitDetailsDto.chapters.length
            }}</b-badge>
          </span> -->
                </div>
                <!-- <div
          class="
            px-2
            bg-white
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <h4>فلتر حسب</h4>
          <div class="d-flex justify-content-between align-items-center">
            <span> نوع المستندات: </span>

            <ek-input-select
              style="min-width: 100px"
              :options="lessonTypeList"
              name="اختر المستند"
              placeholder="اختر المستند"
              v-model="valType"
              @input="sendValueToFilter(valType)"
            >
            </ek-input-select>
          </div>
        </div> -->
                <b-card>
                    <ek-table
                        :items="unitDetailsDto.chapters"
                        :columns="lessonUnitcols"
                        @details="getLessonDetails($event)"
                        :no_select="true"
                        :no_delete="true"
                    >
                        <!-- <template
                            slot="items.dateCreated"
                            slot-scope="{ value }"
                        >
                            <span>{{
                                new Date(value).toLocaleDateString()
                            }}</span>
                        </template>
                        <template
                            slot="items.questionsCount"
                            slot-scope="{ value }"
                        >
                            <span>{{ value }}</span>
                        </template>
                        <template
                            slot="items.lessonType"
                            slot-scope="{ value }"
                        >
                            <span>{{
                                value
                                    ? lessonTypeList.find(
                                          (ele) => ele.id == value
                                      ).name
                                    : "---"
                            }}</span>
                        </template> -->
                    </ek-table>
                </b-card>
            </b-col>
            <!-- <b-col cols="4">
        <b-card v-if="!unitLessonDto.id">
          <template #header>
            <span class="text-center">اختر درس لعرض التفاصيل</span>
          </template>
        </b-card>
        <unit-lesson-details
          v-if="unitLessonDto.lessonType == 2 || unitLessonDto.lessonType == 1"
          :unitLessonDto="unitLessonDto"
        >
        </unit-lesson-details>
        <QuizQuestions
          v-if="unitLessonDto.lessonType == 3"
          :isPlus="true"
          :lessonId="unitLessonDto.id"
          :headers="lessonsQuizUnit"
          :questions="unitLessonDto.questions"
        ></QuizQuestions>
      </b-col> -->
        </b-row>
    </div>
</template>

<script>
// import UnitLessonDetails from "../components/UnitLessonDetails.vue";
import { mapActions, mapState, mapGetters } from "vuex";
// import QuizQuestions from "../../lessons/components/quiz/QuizQuestions.vue";
export default {
    components: {
        // UnitLessonDetails,
        // QuizQuestions,
    },
    props: ["id"],
    data() {
        return {
            valType: 0,

            options: [
                { text: "مجاني", value: true },
                { text: "غير مجاني", value: false },
            ],
        };
    },
    methods: {
        ...mapActions([
            "getByIdUnit",
            "getByIdVideo",
            "getByIdFile",
            "getByIdQuiz",
            "getIdLessonUnit",
            "decryptFile",
            "updateUnit",
            "deleteRangeLessons",
            "deleteUnit",
            "getSettingSemester"
        ]),
        deleteUnits(id) {
            this.deleteUnit(id);
        },
        deleteUnitLesson(id) {
            this.deleteRangeLessons(id);
        },
        getLessonDetails(e) {
            if (e.row.lessonType == 1) {
                this.getByIdVideo(e.row.id).then((res) => {
                    this.decryptFile(res.url).then(({ data }) => {
                        this.$store.commit("UPDATE_DTO_URL", data);
                    });
                });
            } else if (e.row.lessonType == 3) {
                this.getByIdQuiz(e.row.id);
            } else {
                this.getByIdFile(e.row.id).then((res) => {
                    this.decryptFile(res.url).then(({ data }) => {
                        this.$store.commit("UPDATE_DTO_URL", data);
                    });
                });
            }
        },
        sendValueToFilter(val) {
            this.getIdLessonUnit(val);
        },
        editDetailsUnit() {
            this.updateUnit({
                id: this.unitDetailsDto.id,
                name: this.unitDetailsDto.name,
                semesterId: this.unitDetailsDto.semesterId,
                subjectId: this.unitDetailsDto.subjectId,
                isFree: this.unitDetailsDto.isFree,
                price: this.unitDetailsDto.price,
                order: this.unitDetailsDto.order,
            });
        },
    },
    computed: {
        ...mapState({
            unitDetailsDto: ({ units }) => units.unitDetailsDto,

            semesterList: ({ settings }) => settings.semesterList.semesters,
            unitsOptions: ({ units }) => units.unitsOptions,
            lessonUnitcols: ({ units }) => units.lessonUnitcols,
            lessonTypeList: ({ units }) => units.lessonTypeList,
            unitLessonDto: ({ units }) => units.unitLessonDto,
            unitDto: ({ units }) => units.unitDto,
            lessonForm: ({ lessons }) => lessons.lessonForm,
            lessonsQuizUnit: ({ lessons }) => lessons.lessonsQuizUnit,
            filterValueType: ({ units }) => units.filterValueType,
            lessonsQuizHeader: (state) => state.lessons.lessonsQuizHeader,
            teachersList: ({ teachers }) => teachers.teachers,
            subjectOptions: ({ subjects }) => subjects.subjectOptions,
        }),
        ...mapGetters(["filterTableLessonUnit", "resaultUnitLessons"]),
    },
    created() {
        this.getByIdUnit(this.id);
        this.lessonForm.unitId = this.id;
        this.getSettingSemester();

    },
    beforeDestroy() {
        this.$store.commit("RESET_DETAILS_UNIT");
    },
};
</script>
